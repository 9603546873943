export interface FeatureOptions {
  allowed: boolean;
  allowedRoles?: string[];
  maxTeamMembers?: number;
}

export interface Feature {
  feature: string;
  options: FeatureOptions;
}

export interface Plan {
  planId: string;
  features: Feature[];
}

export interface PlanApiResponse {
  plans: Plan[];
  currentPlan: string;
}

export enum FeatureNames {
  CUSTOM_BOTS = 'CUSTOM_BOTS',
  SLA_STATUS = 'SLA_STATUS',
  ZOHO_CRM = 'ZOHO_CRM',
}
