import { Access } from '../../../../shared/typings/user';
import { WabaIntegrationMemberStatus } from '../../../../shared/typings/waba';
import { WabaIntegrationAssignee } from '../../../../shared/typings/waba';
import { Message } from './message-types';

export interface Address {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  countryCode?: string;
  type?: string;
}

interface ContactEmail {
  email?: string;
  type?: string;
}

interface ContactPhone {
  phone?: string;
  type?: string;
  isOnWhatsApp?: boolean;
}

interface ContactUrls {
  url?: string;
  type?: string;
}

interface ContactName {
  fullName: string;
  firstName: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  prefix?: string;
}

interface ContactOrganisation {
  company?: string;
  department?: string;
  title?: string;
}

export interface ContactData {
  addresses?: Array<Address>;
  birthday?: string;
  emails?: Array<ContactEmail>;
  name: ContactName;
  org?: ContactOrganisation;
  phones?: Array<ContactPhone>;
  urls?: Array<ContactUrls>;
}

export type ChatAnalytics = {
  chatMessageId: string;
  chatId: string;
  orgId: string;
  sentCount: number;
  deliveryCount: number;
  readCount: number;
  repliesCount: number;
  unreadCount: number;
  noResponseCount: number;
};

export interface TeamMember {
  id: string;
  name: string;
  phone: string;
  role: string;
  inviteId?: string;
  invitedOn?: number;
  status: WabaIntegrationMemberStatus | null;
  statusExpiration: string | null;
  orgRoleId?: string;
  orgRoleName?: string;
  email?: string;
  provider?: string;
  assignedIntegrationsCount?: number;
  primaryIntegrationId?: string;
  primaryIntegrationName?: string;
  assignments?: WabaIntegrationAssignee[];
}

export interface GetTeamMembers {
  teamMembers: TeamMember[];
  name: string;
  isDefault: boolean;
}

export interface GetUploadCredentials {
  credentials: {
    AccessKeyId: string;
    SecretAccessKey: string;
    SessionToken: string;
    Expiration: string;
  };
}

export interface UploadCredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
  expiration: string;
}

export enum ChatWindowSourcePage {
  BROADCAST = 'BROADCAST',
  CHAT = 'CHAT',
  CUSTOMERS = 'CUSTOMERS',
}

export enum SendMessageType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
  CONTACT = 'contact',
}

export enum AttachmentTypes {
  MEDIA = 'media',
  AUDIO = 'audio',
  DOCUMENT = 'document',
  PDF = 'pdf',
  TEMPLATE = 'template',
  PRODUCT = 'product',
  CATALOGUE = 'catalogue',
}

export enum CommerceTypes {
  PRODUCT = 'product',
  CATALOGUE = 'catalogue',
}

export enum ChatListReducerActionTypes {
  SEARCH = 'searchTerm',
  FORWARD_SEARCH = 'forwardSearchTerm',
  SEARCH_STATUS = 'isSearchActive',
  STATUS = 'status',
  TAGS = 'tags',
  WABA = 'waba',
  SLA_STATUS = 'slaStatus',
  ASSIGNED_USER = 'assignedUserId',
  RESET = 'reset',
  CUSTOM_LIST = 'customList',
  CURRENT_CUSTOM_LIST_ID = 'currentCustomListId',
  LEAD_CUSTOM_FIELD_LIST = 'leadCustomFieldIdList',
  MIXED = 'mixed',
  TAG_IDS = 'tagIds',
}

export type ChatFilterSlaStatus = 'breached' | 'non-breached';

export interface ChatListAtom {
  [ChatListReducerActionTypes.SEARCH]: string;
  [ChatListReducerActionTypes.FORWARD_SEARCH]?: string;
  [ChatListReducerActionTypes.SEARCH_STATUS]: boolean;
  [ChatListReducerActionTypes.STATUS]: ChatStatuses;
  [ChatListReducerActionTypes.TAGS]: string[];
  [ChatListReducerActionTypes.TAG_IDS]: string[];
  [ChatListReducerActionTypes.WABA]: string[];
  [ChatListReducerActionTypes.SLA_STATUS]: ChatFilterSlaStatus;
  [ChatListReducerActionTypes.ASSIGNED_USER]: string;
  [ChatListReducerActionTypes.CURRENT_CUSTOM_LIST_ID]: string;
  [ChatListReducerActionTypes.LEAD_CUSTOM_FIELD_LIST]?: {
    listId: string;
    fieldId: string;
  };
  [ChatListReducerActionTypes.CUSTOM_LIST]: {
    [key: string]: string;
  };
}

export enum SendMessageTimeType {
  NOW = 'now',
  SCHEDULE = 'schedule',
  NONE = 'none',
}

export enum ChatStatuses {
  ALL = 'all',
  OPEN = 'open',
  CLOSED = 'closed',
  READ = 'read',
  UNREAD = 'unread',
  WAITING_FOR_RESPONSE = 'waiting_for_response',
  DONE = 'done',
  UNRESOLVED = 'unresolved',
}

export const ChatStatusOptions = [
  {
    name: 'All',
    value: ChatStatuses.ALL,
  },
  {
    name: 'Unread',
    value: ChatStatuses.UNREAD,
  },
  {
    name: 'Read',
    value: ChatStatuses.READ,
  },
  {
    name: 'Expired',
    value: ChatStatuses.CLOSED,
  },
  {
    name: 'Open',
    value: ChatStatuses.OPEN,
  },
  {
    name: 'Awaiting reply',
    value: ChatStatuses.WAITING_FOR_RESPONSE,
  },
  {
    name: 'Resolved',
    value: ChatStatuses.DONE,
  },
];

export const DEFAULT_CHAT_FILTERS = {
  searchTerm: '',
  isSearchActive: false,
  assignedUserId: '',
  tags: [] as string[],
  status: ChatStatuses.ALL,
  customList: {} as { [key: string]: string },
  currentCustomListId: '',
  waba: [] as string[],
  slaStatus: 'non-breached' as ChatFilterSlaStatus,
  tagIds: [] as string[],
};

export type ChatListReducerAction =
  | SearchActive
  | StatusAction
  | SearchTerm
  | Tags
  | AssignUser
  | CurrentCustomList
  | SelectListField
  | Waba
  | SlaStatus
  | Reset
  | Mixed
  | LeadCustomFieldList
  | ForwardSearchTerm
  | TagIds;

interface SearchActive {
  type: ChatListReducerActionTypes.SEARCH_STATUS;
  data: boolean;
}
interface StatusAction {
  type: ChatListReducerActionTypes.STATUS;
  data: ChatStatuses;
}

interface SearchTerm {
  type: ChatListReducerActionTypes.SEARCH;
  data: string;
}
interface ForwardSearchTerm {
  type: ChatListReducerActionTypes.FORWARD_SEARCH;
  data: string;
}
interface Tags {
  type: ChatListReducerActionTypes.TAGS;
  data: string | string[];
}

interface TagIds {
  type: ChatListReducerActionTypes.TAG_IDS;
  data: string | string[];
}

interface Waba {
  type: ChatListReducerActionTypes.WABA;
  data: string | string[];
}

interface SlaStatus {
  type: ChatListReducerActionTypes.SLA_STATUS;
  data: ChatFilterSlaStatus;
}

interface AssignUser {
  type: ChatListReducerActionTypes.ASSIGNED_USER;
  data: string;
}

interface CurrentCustomList {
  type: ChatListReducerActionTypes.CURRENT_CUSTOM_LIST_ID;
  data: string;
}
interface LeadCustomFieldList {
  type: ChatListReducerActionTypes.LEAD_CUSTOM_FIELD_LIST;
  data: { listId: string; fieldId: string };
}
interface SelectListField {
  type: ChatListReducerActionTypes.CUSTOM_LIST;
  data: {
    listId: string;
    fieldId: string;
  };
}

interface Reset {
  type: ChatListReducerActionTypes.RESET;
  data: undefined;
}

interface Mixed {
  type: ChatListReducerActionTypes.MIXED;
  data: Partial<typeof DEFAULT_CHAT_FILTERS>;
}

export interface InternalNote {
  id: string;
  note: string;
  createdBy: string;
  updatedBy: string;
  dateCreated: string;
  dateUpdated: string;
}

export interface InternalNotesListPaginationData {
  nextNoteId?: string;
  nextNoteDate?: string;
  hasMore?: boolean;
  totalCount: number;
}

export interface InternalNotesListResponse
  extends InternalNotesListPaginationData {
  notes: InternalNote[];
}

export interface CreateInternalNoteRequest extends Partial<InternalNote> {
  customerId: string;
}

export interface ChatListPaginationData {
  lastTimestamp?: string;
}

export interface CustomerChatMeta {
  customerName: string;
  customerId: string;
  customerChatId: string;
  assignedUserId: string;
  optIn: boolean | null;
  assignedUserName: string;
  internalFields: CustomChatMetaFields[];
  customFields: CustomChatMetaFields[];
  tags: ConversationTag[];
  integrationId: string;
  integrationWabaNumber: string;
  integrationWabaPhoneName: string;
  integrationDisplayName: string;
  customerIsBlocked: boolean | null;
  customerPhone: string;
  isDone?: boolean;
  phonebookName?: string;
  imageUrl: string;
}

export type CustomChatMetaFields =
  | CustomChatMetaTextField
  | CustomChatMetaSingleSelectField;

export interface CustomerDetails {
  id: string;
  dateCreated: Date;
  dateUpdated: Date;
  name: string;
  phoneNumber: string;
  source: string;
  orgId: string;
}

export interface CustomFieldsData {
  customFields: CustomFields[];
  internalFields: CustomFields[];
}

export type CustomFields = GetPrimitiveCustomField | GetSingleSelectCustomField;

export type CustomFieldsDetailsRequest = {
  name: string;
  type: CustomFieldsTypes;
  list?: { name: string }[];
};

export type CustomFieldsDetails = {
  id: string;
  name: string;
  uniqueName: string;
} & (
  | {
      list?: never;
      type:
        | CustomFieldsTypes.DATE
        | CustomFieldsTypes.NUMBER
        | CustomFieldsTypes.TEXT
        | CustomFieldsTypes.BOOLEAN;
    }
  | {
      type: CustomFieldsTypes.LIST;
      list: Array<{
        id: string;
        name: string;
        position: number;
        visibility: boolean;
      }>;
    }
);
export interface GetAllCustomFields<T> {
  customFields: T[];
  internalFields: T[];
}

export interface CustomChatMetaSingleSelectField {
  id: string;
  name: string;
  type: 'single_select';
  value: string;
  customFieldValueId: string;
}

export interface CustomChatMetaTextField {
  id: string;
  name: string;
  value: string;
  type: 'text' | 'date' | 'number' | 'boolean';
}

export interface SingleSelectCustomField {
  customerCount: number;
  id: string;
  name: string;
  position: number;
}

export interface GetSingleSelectCustomField
  extends Omit<CustomChatMetaSingleSelectField, 'customFieldValueId'> {
  uniqueName: string;
  list: SingleSelectCustomField[];
}

export interface GetPrimitiveCustomField extends CustomChatMetaTextField {
  uniqueName: string;
}
export interface LeadStatusListProps {
  customerCount: number;
  name: string;
  id: string;
  position: number;
}
export interface GetAllCustomFieldsLeadStatusProps {
  customField: {
    id: string;
    name: string;
    list: LeadStatusListProps[];
    type: string;
    uniqueName: string;
  };
}
export interface ConversationTag {
  id: string;
  name: string;
  bgColorCode: string;
  fontColorCode: string;
  createdOn?: string;
}

export interface GetConversationTagsPaginationData {
  hasMore: boolean;
  nextSearchAfter: string | null;
}

export interface GetConversationTagsData
  extends GetConversationTagsPaginationData {
  data: ConversationTag[];
}

export interface ConversationTagsData
  extends GetConversationTagsPaginationData {
  tags: ConversationTag[];
}

export interface GroupChatMeta {
  groupId: string;
  groupChatName: string;
  groupChatId: string;
  memberCount?: number;
  showMemberCount?: boolean;
}

export enum EligibleMessageTypes {
  'TEMPLATE' = 'TEMPLATE',
  'ANY' = 'ANY',
  // Only On UI Side
  'NONE' = 'NONE',
}

export interface GetPaginatedChatReturn {
  messages: Message[];
  eligibleMessageTypes: Array<EligibleMessageTypes>;
  canWrite: boolean;
  canRead: boolean;
  optIn: boolean;
}

export interface ChatPaginationData {
  lastUpdatedTimestamp: number;
}

export interface DirectSendFormValues {
  phone: string;
  selectedCountryId: string;
}

export interface UserDetails {
  id: string;
  dateCreated: Date;
  dateUpdated: Date;
  name: string | null;
  email: string;
  phone: string;
  countryCode: string;
  orgId: string;
  state?: any;
  city?: any;
  timezone?: any;
  currencyCode?: any;
  provider: string;
  isOrgOwner: boolean;
  access: Access;
  closedConversationTemplateViewTypes: {
    type: FrictionlessTemplateTypes;
  }[];
}

export enum GroupLevelAccess {
  FULL_ACCESS = 'FULL_ACCESS',
  SEND_ONLY_ACCESS = 'SEND_ONLY_ACCESS',
  NO_ACCESS = 'NO_ACCESS',
}

export interface ChannelAccessUpdate {
  groupId: string;
  userId: string;
  accessLevel: GroupLevelAccess;
  permissionId: string;
  orgId?: string;
}

export enum AssigneeUpdatesType {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
}

export interface AssignedSocketUpdates {
  chatId: string;
  chatType: string;
  chatTypeId: string;
  userId: string;
  type: AssigneeUpdatesType.UNASSIGNED | AssigneeUpdatesType.ASSIGNED;
  integrationId: string;
  validUserIds?: string[];
  assigneeUpdatedTime?: number; // from socket
  notificationTime?: string; // from FCM notification
}

export enum CustomFieldsTypes {
  TEXT = 'text',
  DATE = 'date',
  NUMBER = 'number',
  LIST = 'single_select',
  BOOLEAN = 'boolean',
}

export enum ChatsUpdatesTypes {
  DELETED = 'DELETED',
}

export interface ChatsUpdates {
  chatIds: string[];
  orgId: string;
  type: ChatsUpdatesTypes;
}

export enum OperationStatus {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export interface Sheet {
  name: string;
  totalRecords: number;
  successfulRecords: number;
  failedRecords: number;
}

export interface AdditionalData {
  fileName: string;
  totalRecords: number;
  successfulRecords: number;
  failedRecords: number;
  sheets: Sheet[];
  failedCsvLink: string | null;
}

export interface ImportExcelSocket {
  operationId: string;
  orgId: string;
  status: OperationStatus;
  type: string;
  additionalData: AdditionalData;
}

export interface AgentStatusSocket {
  userId: string;
  teamId: string;
  memberId: string;
  status: WabaIntegrationMemberStatus | null;
  statusExpiration: string | null;
}

export interface ErrorMessageData {
  reasons: string[];
  infoUrl: string;
}

export interface ErrorMessageResponseData extends ErrorMessageData {
  message: string;
}

export interface ErrorMessageResponse {
  errorMessages: ErrorMessageResponseData[];
}

export enum FrictionlessTemplateTypes {
  IMAGE = 'IMAGE_MESSAGE',
  VIDEO = 'VIDEO_MESSAGE',
  AUDIO = 'AUDIO_MESSAGE',
  PDF = 'PDF_MESSAGE',
  SINGLE_MESSAGE = 'SINGLE_MESSAGE',
}

export interface FrictionlessTemplateData {
  type: FrictionlessTemplateTypes;
  name: string;
  templateId: number;
  language: string;
}

export interface GetFrictionlessTemplatesResponse {
  templates: FrictionlessTemplateData[];
}

export interface FrictionlessMessageDataToSend {
  type: FrictionlessTemplateTypes;
  // name: string;
  // templateId: number;
  // language: string;
  fileName: string;
  link: string;
  text: string;
}
