import { Feature, FeatureNames } from '../../../features/typings';
import { IntegrationType } from '../../../integrations/typings';

export const planAllowsAccessTo = (
  featureName: string | undefined,
  availableFeatures: Feature[] | undefined,
  currentPlan: string | undefined
) => {
  // Enabling blocker for SLA only
  if (
    featureName &&
    ['SLA_STATUS', IntegrationType.ZOHO_CRM, FeatureNames.CUSTOM_BOTS].includes(
      featureName
    ) &&
    currentPlan
  ) {
    return availableFeatures?.some(
      ({ feature, options }) => feature === featureName && options?.allowed
    );
  }
  return true;
};
