import { FC, SVGProps } from 'react';

const CustomersIcon: FC<SVGProps<SVGSVGElement>> = ({
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill={color}
      stroke={color}
      strokeWidth="1"
      {...props}
    >
      <path
        d="M6.66003 1.78C5.0733 1.78 3.78003 3.07327 3.78003 4.66V12.34C3.78003 13.9267 5.0733 15.22 6.66003 15.22H14.34C15.9268 15.22 17.22 13.9267 17.22 12.34V4.66C17.22 3.07327 15.9268 1.78 14.34 1.78H6.66003ZM6.66003 2.42H14.34C14.4536 2.42 14.5511 2.4682 14.66 2.48437V14.5156C14.5511 14.5318 14.4536 14.58 14.34 14.58H6.66003C5.41923 14.58 4.42003 13.5808 4.42003 12.34V4.66C4.42003 3.4192 5.41923 2.42 6.66003 2.42ZM15.3 2.65375C16.0527 3.01406 16.58 3.76702 16.58 4.66V4.98H15.3V2.65375ZM9.54003 4.34C7.24631 4.34 5.38003 6.20628 5.38003 8.5C5.38003 10.7937 7.24631 12.66 9.54003 12.66C11.8337 12.66 13.7 10.7937 13.7 8.5C13.7 6.20628 11.8337 4.34 9.54003 4.34ZM9.54003 4.98C11.4879 4.98 13.06 6.55217 13.06 8.5C13.06 9.40307 12.7192 10.2227 12.1625 10.845C11.5144 10.1869 10.5768 9.78 9.54003 9.78C8.50289 9.78 7.56687 10.1885 6.91878 10.8469C6.36126 10.2243 6.02003 9.40387 6.02003 8.5C6.02003 6.55217 7.5922 4.98 9.54003 4.98ZM15.3 5.62H16.58V8.18H15.3V5.62ZM9.54003 6.26C9.03336 6.26 8.61264 6.46152 8.34065 6.7675C8.06867 7.07348 7.94003 7.46889 7.94003 7.86C7.94003 8.25111 8.06867 8.64652 8.34065 8.9525C8.61264 9.25848 9.03336 9.46 9.54003 9.46C10.0467 9.46 10.4674 9.25848 10.7394 8.9525C11.0114 8.64652 11.14 8.25111 11.14 7.86C11.14 7.46889 11.0114 7.07348 10.7394 6.7675C10.4674 6.46152 10.0467 6.26 9.54003 6.26ZM9.54003 6.9C9.8867 6.9 10.106 7.01848 10.2607 7.1925C10.4153 7.36652 10.5 7.61111 10.5 7.86C10.5 8.10889 10.4153 8.35348 10.2607 8.5275C10.106 8.70152 9.8867 8.82 9.54003 8.82C9.19336 8.82 8.97409 8.70152 8.8194 8.5275C8.66472 8.35348 8.58003 8.10889 8.58003 7.86C8.58003 7.61111 8.66472 7.36652 8.8194 7.1925C8.97409 7.01848 9.19336 6.9 9.54003 6.9ZM15.3 8.82H16.58V11.38H15.3V8.82ZM9.54003 10.42C10.4022 10.42 11.1697 10.7579 11.6938 11.2825C11.0986 11.7434 10.3531 12.02 9.54003 12.02C8.72774 12.02 7.98301 11.7438 7.38815 11.2837C7.91221 10.7594 8.67787 10.42 9.54003 10.42ZM15.3 12.02H16.58V12.34C16.58 13.233 16.0527 13.9859 15.3 14.3462V12.02Z"
        fill={color}
        stroke={color}
        stroke-width="0.4"
      />
    </svg>
  );
};

export default CustomersIcon;
