import { MessagePayload } from 'firebase/messaging';

const usePushNotification = () => {
  const isFirebaseSWRegistered = async () => {
    if (!navigator.serviceWorker) {
      return false;
    }
    const controller = navigator.serviceWorker.controller;
    if (
      controller &&
      controller.scriptURL.includes('firebase-messaging-sw.js')
    ) {
      return true;
    }
    return false;
  };
  const showPushNotification = async (payload: MessagePayload) => {
    try {
      const swRegisterd = await isFirebaseSWRegistered();

      if (
        'serviceWorker' in navigator &&
        navigator.serviceWorker.controller &&
        swRegisterd
      ) {
        navigator.serviceWorker.controller.postMessage({
          type: 'SHOW_NOTIFICATION',
          payload,
        });
      } else {
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js')
          .then((registration) => {
            if (registration.active) {
              // Service Worker is already active
              registration.active.postMessage({
                type: 'SHOW_NOTIFICATION',
                payload,
              });
            } else {
              // Wait for the controllerchange event
              navigator.serviceWorker.addEventListener(
                'controllerchange',
                function () {
                  // Post message once the SW is in control
                  if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage({
                      type: 'SHOW_NOTIFICATION',
                      payload,
                    });
                  }
                }
              );
            }
          })
          .catch((error) => {
            console.error('Service Worker registration failed:', error);
          });
      }
    } catch (error) {
      // Do nothing
    }
  };

  return { showPushNotification };
};

export default usePushNotification;
