import { FC, SVGProps } from 'react';

const GroupIconSmall: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  color,
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      {...props}
    >
      <path
        d="M10.5 3.16667C9.21094 3.16667 8.16667 4.21094 8.16667 5.5C8.16667 6.78906 9.21094 7.83333 10.5 7.83333C11.7891 7.83333 12.8333 6.78906 12.8333 5.5C12.8333 4.21094 11.7891 3.16667 10.5 3.16667ZM10.5 4.5C11.0521 4.5 11.5 4.94792 11.5 5.5C11.5 6.05208 11.0521 6.5 10.5 6.5C9.94792 6.5 9.5 6.05208 9.5 5.5C9.5 4.94792 9.94792 4.5 10.5 4.5ZM5.5 5.16667C4.57813 5.16667 3.83333 5.91146 3.83333 6.83333C3.83333 7.75521 4.57813 8.5 5.5 8.5C6.42187 8.5 7.16667 7.75521 7.16667 6.83333C7.16667 5.91146 6.42187 5.16667 5.5 5.16667ZM15.5 5.16667C14.5781 5.16667 13.8333 5.91146 13.8333 6.83333C13.8333 7.75521 14.5781 8.5 15.5 8.5C16.4219 8.5 17.1667 7.75521 17.1667 6.83333C17.1667 5.91146 16.4219 5.16667 15.5 5.16667ZM5.5 6.3151C5.78646 6.3151 6.01823 6.54687 6.01823 6.83333C6.01823 7.11979 5.78646 7.35156 5.5 7.35156C5.21354 7.35156 4.98177 7.11979 4.98177 6.83333C4.98177 6.54687 5.21354 6.3151 5.5 6.3151ZM15.5 6.3151C15.7865 6.3151 16.0182 6.54687 16.0182 6.83333C16.0182 7.11979 15.7865 7.35156 15.5 7.35156C15.2135 7.35156 14.9818 7.11979 14.9818 6.83333C14.9818 6.54687 15.2135 6.3151 15.5 6.3151ZM10.5 9.16667C9.51563 9.16667 7.91667 9.47917 6.86979 10.099C6.44271 9.9375 5.97396 9.83333 5.5 9.83333C4.00781 9.83333 2.5 10.7656 2.5 11.7891V13.8333H18.5V11.7891C18.5 10.7656 16.9922 9.83333 15.5 9.83333C15.026 9.83333 14.5573 9.9375 14.1302 10.099C13.0833 9.47917 11.4844 9.16667 10.5 9.16667ZM10.5 10.5C11.9219 10.5 13.5807 11.1406 13.8333 11.5443V12.5H7.16667V11.5443C7.41927 11.1406 9.07813 10.5 10.5 10.5ZM5.5 11.1667C5.63281 11.1667 5.75 11.1901 5.8724 11.2083C5.84635 11.3021 5.83333 11.3984 5.83333 11.5V12.5H3.83333V11.8411C3.95573 11.6276 4.63281 11.1667 5.5 11.1667ZM15.5 11.1667C16.3672 11.1667 17.0443 11.6276 17.1667 11.8411V12.5H15.1667V11.5C15.1667 11.3984 15.1536 11.3021 15.1276 11.2083C15.25 11.1901 15.3672 11.1667 15.5 11.1667Z"
        fill={color}
      />
    </svg>
  );
};

export default GroupIconSmall;
