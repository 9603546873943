import { GEO_IP_STORAGE_KEYS } from '../../../../shared/typings';

const cleanLocalStorageUseCase = () => {
  const userGeo = localStorage.getItem(GEO_IP_STORAGE_KEYS.GEO_DATA);
  const cpDetails = localStorage.getItem('CHANNEL_PARTNER_DETAILS');
  localStorage.clear();
  if (userGeo) {
    localStorage.setItem(GEO_IP_STORAGE_KEYS.GEO_DATA, userGeo);
  }
  if (cpDetails) {
    localStorage.setItem('CHANNEL_PARTNER_DETAILS', cpDetails);
  }
  console.log('local storage cleaned');
};

export default cleanLocalStorageUseCase;
