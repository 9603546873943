import { FC, SVGProps } from 'react';

const AnalyticsIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  fill = 'currentColor',
  size = 24,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill={fill}
      {...props}
    >
      <path
        d="M7.83333 11.8333C8.02222 11.8333 8.18056 11.7694 8.30833 11.6417C8.43611 11.5139 8.5 11.3556 8.5 11.1667V7.83333C8.5 7.64444 8.43611 7.48611 8.30833 7.35833C8.18056 7.23056 8.02222 7.16667 7.83333 7.16667C7.64444 7.16667 7.48611 7.23056 7.35833 7.35833C7.23056 7.48611 7.16667 7.64444 7.16667 7.83333V11.1667C7.16667 11.3556 7.23056 11.5139 7.35833 11.6417C7.48611 11.7694 7.64444 11.8333 7.83333 11.8333ZM10.5 11.8333C10.6889 11.8333 10.8472 11.7694 10.975 11.6417C11.1028 11.5139 11.1667 11.3556 11.1667 11.1667V5.83333C11.1667 5.64444 11.1028 5.48611 10.975 5.35833C10.8472 5.23056 10.6889 5.16667 10.5 5.16667C10.3111 5.16667 10.1528 5.23056 10.025 5.35833C9.89722 5.48611 9.83333 5.64444 9.83333 5.83333V11.1667C9.83333 11.3556 9.89722 11.5139 10.025 11.6417C10.1528 11.7694 10.3111 11.8333 10.5 11.8333ZM13.1667 11.8333C13.3556 11.8333 13.5139 11.7694 13.6417 11.6417C13.7694 11.5139 13.8333 11.3556 13.8333 11.1667V9.83333C13.8333 9.64444 13.7694 9.48611 13.6417 9.35833C13.5139 9.23056 13.3556 9.16667 13.1667 9.16667C12.9778 9.16667 12.8194 9.23056 12.6917 9.35833C12.5639 9.48611 12.5 9.64444 12.5 9.83333V11.1667C12.5 11.3556 12.5639 11.5139 12.6917 11.6417C12.8194 11.7694 12.9778 11.8333 13.1667 11.8333ZM5.83333 14.5C5.46667 14.5 5.15278 14.3694 4.89167 14.1083C4.63056 13.8472 4.5 13.5333 4.5 13.1667V3.83333C4.5 3.46667 4.63056 3.15278 4.89167 2.89167C5.15278 2.63056 5.46667 2.5 5.83333 2.5H15.1667C15.5333 2.5 15.8472 2.63056 16.1083 2.89167C16.3694 3.15278 16.5 3.46667 16.5 3.83333V13.1667C16.5 13.5333 16.3694 13.8472 16.1083 14.1083C15.8472 14.3694 15.5333 14.5 15.1667 14.5H5.83333ZM5.83333 13.1667H15.1667V3.83333H5.83333V13.1667Z"
        fill={fill}
      />
    </svg>
  );
};

export default AnalyticsIcon;
