/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { FC, CSSProperties } from 'react';

type CustomTooltipProps = TooltipProps & {
  styles?: { tooltip: CSSProperties };
};

const StyledTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
  <Tooltip arrow classes={{ popper: className }} {...props} />
))(({ styles: { tooltip } = {} }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2d2d2d',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2d2d2d',
    ...tooltip,
  },
}));

const QSTooltip: FC<CustomTooltipProps> = (props) => {
  return <StyledTooltip {...props} />;
};

export default QSTooltip;
