import useQuery from './useQuery';
import { PlanApiResponse } from '../../modules/features/typings';
import { fetchCurrentPlan } from '../../modules/features/requests';
import { getAllPlansAtom } from '../../modules/features/state';

const useCurrentPlan = () => {
  const {
    requestData: { data, loading },
  } = useQuery<PlanApiResponse>({
    requestAtom: getAllPlansAtom,
    queryFunction: fetchCurrentPlan,
  });
  const currentPlan = data?.plans.find(
    ({ planId }) => planId === data.currentPlan
  );
  return {
    availableFeatures: currentPlan?.features || [],
    currentPlan: currentPlan?.planId,
    loading,
  };
};

export default useCurrentPlan;
