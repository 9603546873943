import { useMemo } from 'react';
import { useSubscribedPlan } from '../../../renew-plan/v1/hooks/use-subscribed-plan';
import { planAllowsAccessTo } from '../utils/features';
import { PlanAddonTypes } from '../../../renew-plan/v1/typings';
import { FeatureNames } from '../../../features/typings';
import useCurrentPlan from '../../../../shared/hooks/use-current-plan';

export const useShowBotStudioBlocker = () => {
  const {
    availableFeatures,
    currentPlan,
    loading: currentPlanLoading,
  } = useCurrentPlan();
  const {
    subscribedPlan,
    loading: subscribedPlanLoading,
    error: subscribedPlanError,
  } = useSubscribedPlan();

  const showBotStudioBlocker = useMemo(() => {
    const planAllowsAccessToBotStudio = planAllowsAccessTo(
      FeatureNames.CUSTOM_BOTS,
      availableFeatures,
      currentPlan
    );

    const isCustomBotsAvailableInAddons =
      !subscribedPlanLoading &&
      !subscribedPlanError &&
      subscribedPlan?.addons?.some((addon) => {
        return (
          addon.addonId === PlanAddonTypes.ComplexBot &&
          new Date(addon.endDate) > new Date()
        );
      });

    return !planAllowsAccessToBotStudio && !isCustomBotsAvailableInAddons;
  }, [
    availableFeatures,
    currentPlan,
    subscribedPlan,
    subscribedPlanLoading,
    subscribedPlanError,
  ]);

  const loading = currentPlanLoading || subscribedPlanLoading;

  return { showBotStudioBlocker, loading };
};
