export enum WabaChannelType {
  ALL = 'ALL',
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK_MESSENGER = 'FACEBOOK_MESSENGER',
  SMS = 'SMS',
}

export enum WabaChannelStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  AT_RISK = 'AT_RISK',
}

export enum WabaChannelSort {
  DATE_CREATED_ASC = 'DATE_CREATED_ASC',
  DATE_CREATED_DESC = 'DATE_CREATED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum WabaMemberSort {
  DATE_JOINED_ASC = 'DATE_JOINED_ASC',
  DATE_JOINED_DESC = 'DATE_JOINED_DESC',
  DATE_INVITED_ASC = 'DATE_INVITED_ASC',
  DATE_INVITED_DESC = 'DATE_INVITED_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
}

export enum WabaIntegrationMemberStatus {
  ACTIVE = 'ACTIVE',
  BUSY = 'BUSY',
  ON_BREAK = 'ON_BREAK',
  ON_LEAVE = 'ON_LEAVE',
  OFFLINE = 'OFFLINE',
}

export enum WabaChannelRating {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export interface WabaIntegration {
  id: string;
  name: string;
  displayName: string;
  logo?: string;
  phoneNumber: string;
  status: WabaChannelStatus;
  dateCreated: Date;
  assignedAgentsCount: number;
  type: WabaChannelType;
  assignedRoleId?: string;
  assignedRoleName?: string;
  dateAssigned?: Date;
  primary: boolean;
  qualityRating?: WabaChannelRating;
  messagingLimit?: number | null | 'null' | 'unlimited';
  isVisible?: boolean;
  provider?: WabaIntegrationType;
  isSelfAssigned?: boolean;
  fbBusinessId?: string;
  fbBusinessName: string;
  wabaId: string;
  wabaName?: string;
  showWabaName?: string;
}

export interface WabaIntegrationMember {
  id: string;
  name: string;
  phone: string;
  email: string;
  orgRoleId?: string;
  orgRoleName?: string;
  orgRoleDisplayName?: string;
  assignedIntegrationsCount?: number;
  assignedRoleIdForIntegrationId?: string;
  status: WabaIntegrationMemberStatus | null;
  statusExpiration: string | null;
  primaryIntegrationId?: string;
  primaryIntegrationName?: string;
}

export interface WabaIntegrationPaginationParams {
  teamId?: string;
  sort?: WabaChannelSort;
  sortAfter?: string;
  nextSortAfter?: string;
  status?: WabaChannelStatus;
  searchTerm?: string;
  forMemberId?: string;
  sendOnlyAssignedIntegrations?: boolean;
  excludePrimaryField?: boolean;
  includeAllIntegrations?: boolean;
  integrationsCount?: number;
  integrationTypes?: WabaChannelType[];
  hasMore: boolean;
  nonPaginated?: boolean;
}

export interface GetWabaIntegrationsResponse
  extends Pick<WabaIntegrationPaginationParams, 'hasMore' | 'nextSortAfter'> {
  integrationTypes?: WabaChannelType[];
  integrationsCount?: number;
  integrationsCountLimit?: number;
  integrations: WabaIntegration[];
}

export interface WabaIntegrationMembersPaginationParams {
  teamId?: string;
  roleId?: string;
  sort?: WabaMemberSort;
  sortAfter?: string;
  nextSortAfter?: string;
  searchTerm?: string;
  forIntegrationId?: string;
  removeBotUser?: boolean;
  showAvailableMembersFirst?: boolean;
  hasMore?: boolean;
  nonPaginated?: boolean;
  sendOnlyAssignedMembers?: boolean;
  status?: WabaMemberStatus;
}

export enum WabaMemberStatus {
  JOINED = 'joined',
  INVITED = 'invited',
}

export interface GetWabaIntegrationMembersResponse
  extends Pick<
    WabaIntegrationMembersPaginationParams,
    'hasMore' | 'nextSortAfter'
  > {
  membersCount?: number;
  hasPendingInvites?: boolean;
  members: WabaIntegrationMember[];
}

export interface WabaIntegrationAssignee {
  integrationId?: string;
  memberId: string;
  roleId?: string;
  primary?: boolean;
}

export enum WabaIntegrationsActionTypes {
  'UPSERT_INTEGRATION' = 'UPSERT_INTEGRATION',
  'BULK_UPSERT_INTEGRATIONS' = 'BULK_UPSERT_INTEGRATIONS',
  'OVERWRITE_INTEGRATIONS' = 'OVERWRITE_INTEGRATIONS',
  'DELETE_INTEGRATION' = 'DELETE_INTEGRATION',
  'RESET_INTEGRATIONS' = 'RESET_INTEGRATIONS',
}

export interface UpsertWabaIntegrationAction {
  type: WabaIntegrationsActionTypes.UPSERT_INTEGRATION;
  data: Partial<WabaIntegration>;
}

export interface BulkUpsertWabaIntegrationsAction {
  type: WabaIntegrationsActionTypes.BULK_UPSERT_INTEGRATIONS;
  data: Partial<WabaIntegration>[];
}

export interface OverwriteWabaIntegrationsAction {
  type: WabaIntegrationsActionTypes.OVERWRITE_INTEGRATIONS;
  data: WabaIntegration[];
}

export interface DeleteWabaIntegrationAction {
  type: WabaIntegrationsActionTypes.DELETE_INTEGRATION;
  data: string;
}

export interface ResetWabaIntegrationsAction {
  type: WabaIntegrationsActionTypes.RESET_INTEGRATIONS;
  data: undefined;
}

export type WabaIntegrationsReducerActions =
  | UpsertWabaIntegrationAction
  | BulkUpsertWabaIntegrationsAction
  | OverwriteWabaIntegrationsAction
  | DeleteWabaIntegrationAction
  | ResetWabaIntegrationsAction;

export enum WabaIntegrationMembersActionTypes {
  'UPSERT_INTEGRATION_MEMBER' = 'UPSERT_INTEGRATION_MEMBER',
  'BULK_UPSERT_INTEGRATION_MEMBERS' = 'BULK_UPSERT_INTEGRATION_MEMBERS',
  'OVERWRITE_INTEGRATION_MEMBERS' = 'OVERWRITE_INTEGRATION_MEMBERS',
  'DELETE_INTEGRATION_MEMBER' = 'DELETE_INTEGRATION_MEMBER',
  'RESET_INTEGRATION_MEMBERS' = 'RESET_INTEGRATION_MEMBERS',
}

export interface UpsertWabaIntegrationMemberAction {
  type: WabaIntegrationMembersActionTypes.UPSERT_INTEGRATION_MEMBER;
  data: Partial<WabaIntegrationMember>;
}

export interface BulkUpsertWabaIntegrationMembersAction {
  type: WabaIntegrationMembersActionTypes.BULK_UPSERT_INTEGRATION_MEMBERS;
  data: Partial<WabaIntegrationMember>[];
}

export interface OverwriteWabaIntegrationMembersAction {
  type: WabaIntegrationMembersActionTypes.OVERWRITE_INTEGRATION_MEMBERS;
  data: WabaIntegrationMember[];
}

export interface DeleteWabaIntegrationMemberAction {
  type: WabaIntegrationMembersActionTypes.DELETE_INTEGRATION_MEMBER;
  data: string;
}

export interface ResetWabaIntegrationMembersAction {
  type: WabaIntegrationMembersActionTypes.RESET_INTEGRATION_MEMBERS;
  data: undefined;
}

export type WabaIntegrationMembersReducerActions =
  | UpsertWabaIntegrationMemberAction
  | BulkUpsertWabaIntegrationMembersAction
  | OverwriteWabaIntegrationMembersAction
  | DeleteWabaIntegrationMemberAction
  | ResetWabaIntegrationMembersAction;

export enum WabaIntegrationType {
  '360_DIALOG' = '360_DIALOG',
  GUPSHUP = 'GUPSHUP',
  EXOTEL = 'EXOTEL',
  VALUEFIRST = 'VALUEFIRST',
}

export interface WabaMetaData {
  provider: WabaIntegrationType;
  wabaChannelLimit?: number;
  totalWabaChannelCount?: number;
  canAddNewWabaChannel?: boolean;
  isTechProvider: boolean;
  solutionId?: string;
}
