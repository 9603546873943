/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import deepmerge from 'deepmerge';
import {
  CSSProperties,
  Fragment,
  ReactNode,
  ElementType,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import {
  Box,
  FormControl,
  styled,
  BoxProps,
  Select,
  SelectProps,
  MenuItem,
  SxProps,
  TextField,
  TextFieldProps,
  ListSubheader,
  OutlinedInputProps,
  InputBaseComponentProps,
  Tooltip,
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { TEXT_COLOR } from '../../typings';
import { QSTypography } from '../../components/atoms';
import { QSTypographyProps } from '../../components/atoms/typography';
import EmptyDataMessage from '../empty-data-message';

export enum DropdownVariants {
  DEFAULT = 'default',
}

export interface CustomSelectProps {
  styleVariant: DropdownVariants;
}

const styles = {
  [DropdownVariants.DEFAULT]: css`
    .MuiInputBase-root {
      box-shadow: none;
      outline: none;
      font-weight: 700;
      width: max-content;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      padding: 0px;

      .MuiSelect-nativeInput {
        top: 0;
      }

      .MuiSelect-select {
        padding: 12px 32px 12px 12px;
      }

      .MuiSelect-icon {
        fill: rgba(0, 0, 0, 0.23);
      }

      fieldset {
        border: none;
      }

      &:hover {
        border: 1px solid rgb(147, 147, 147);

        .MuiOutlinedInput-notchedOutline {
          border-width: 0;
        }

        .MuiSelect-icon {
          fill: rgb(147, 147, 147);
        }
      }

      &.Mui-focused {
        border: 2px solid #3f906d;

        .MuiSelect-select {
          padding: 11px 31px 11px 11px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-width: 0px;
        }

        .MuiSelect-icon {
          fill: #3f906d;
        }
      }
    }
  `,
};

const StyledFormControl = styled(FormControl)<CustomSelectProps>(
  ({ styleVariant }) => {
    return styles[styleVariant];
  }
);

export interface DropdownProps<T = string | number | undefined> {
  variant?: DropdownVariants;
  width?: string;
  height?: string;
  sx?: SxProps;
  selectProps?: Omit<SelectProps<T>, 'value' | 'defaultValue'>;
  selectSx?: SxProps;
  menuSx?: SxProps;
  modalStyles?: CSSProperties;
  modalPaperSx?: SxProps;
  modalRootSx?: SxProps;
  modalAnchorEl?: HTMLElement;
  disablePortal?: boolean;
  open?: boolean;
  onOpen?: (event: SyntheticEvent) => void;
  onClose?: (event: SyntheticEvent) => void;
  searchable?: boolean;
  searchInputProps?: TextFieldProps;
  searchContainerSx?: SxProps;
  displayEmpty?: boolean;
  renderValue?: (value: T) => ReactNode;
  renderOption?: (option: { name?: string; value: T }) => ReactNode;
  title?: string;
  onChange?: (value: T) => void;
  onMenuItemClick?: (value: T) => void;
  options: { name?: string; value: T }[];
  optionsEmptyMessage?: string;
  value?: T;
  disabled?: boolean;
  placeholder?: string;
  titleContainerProps?: BoxProps;
  titleProps?: QSTypographyProps<'span'>;
  children?: ReactNode;
  containerProps?: BoxProps;
  IconComponent?: ElementType;
  headerTitleIcon?: ReactNode;
  showTooltip?: boolean;
  tooltipTitle?: string;
  placeholderStyles?: SxProps;
}

const DropdownComponent = <T extends string | number | undefined>({
  variant = DropdownVariants.DEFAULT,
  title,
  onChange,
  onMenuItemClick,
  options = [],
  optionsEmptyMessage,
  value,
  disabled,
  sx,
  selectProps,
  selectSx,
  menuSx,
  modalStyles,
  modalPaperSx,
  modalRootSx,
  modalAnchorEl,
  disablePortal,
  open,
  onOpen,
  onClose,
  searchable = false,
  searchInputProps,
  searchContainerSx = {},
  displayEmpty = true,
  renderValue,
  renderOption,
  placeholder,
  titleContainerProps,
  titleProps,
  children,
  containerProps,
  IconComponent,
  headerTitleIcon,
  showTooltip = false,
  tooltipTitle,
  placeholderStyles,
}: DropdownProps<T>) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (
        open &&
        searchable &&
        typeof searchInputRef.current?.focus === 'function'
      ) {
        searchInputRef.current?.focus();
      }
    }, 200);
  }, [open, searchable]);

  const filteredOptions = useMemo(() => {
    return options.filter(({ name }) => {
      if (typeof searchTerm === 'string' && searchTerm.length > 0) {
        return name
          ?.trim()
          ?.toLowerCase()
          .includes(searchTerm?.trim()?.toLowerCase());
      }
      return true;
    });
  }, [options, searchTerm]);

  return (
    <Box {...containerProps}>
      {title && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
          {...titleContainerProps}
        >
          <QSTypography
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              color: TEXT_COLOR,
              ...titleProps?.sx,
            }}
            {...titleProps}
          >
            {title}
          </QSTypography>
          {headerTitleIcon}
        </Box>
      )}
      <StyledFormControl sx={sx} styleVariant={variant}>
        <Tooltip
          title={
            showTooltip &&
            typeof tooltipTitle === 'string' &&
            tooltipTitle.length > 1 &&
            value
              ? tooltipTitle
              : ''
          }
          placement="top"
          arrow
          open={tooltipVisible}
        >
          <Select<T>
            IconComponent={IconComponent ?? ExpandMoreRoundedIcon}
            value={value}
            disabled={disabled}
            onChange={(event) => {
              onChange?.(event.target.value as T);
              setSearchTerm('');
            }}
            disableUnderline={true}
            sx={selectSx}
            MenuProps={{
              sx: menuSx,
              disablePortal,
              componentsProps: {
                root: {
                  style: modalStyles,
                },
              },
              slotProps: {
                paper: {
                  sx: modalPaperSx,
                },
                root: {
                  sx: modalRootSx,
                  anchorEl: modalAnchorEl,
                },
              },
            }}
            open={open}
            onOpen={(event) => {
              onOpen?.(event);
              setTooltipVisible(false);
              setTimeout(() => {
                if (typeof searchInputRef.current?.focus === 'function') {
                  searchInputRef.current?.focus();
                }
              }, 200);
            }}
            onClose={onClose}
            displayEmpty={displayEmpty}
            renderValue={renderValue}
            {...selectProps}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
          >
            {searchable ? (
              <ListSubheader
                onKeyDown={(event) => event.stopPropagation()}
                sx={{
                  height: '40px',
                  width: '100%',
                  lineHeight: '40px',
                  padding: '0px',
                  borderBottom: '1px solid #E0E0EA',
                  ...searchContainerSx,
                }}
              >
                <TextField
                  autoFocus={true}
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="new-password"
                  spellCheck="false"
                  fullWidth={true}
                  multiline={false}
                  disabled={disabled}
                  placeholder={placeholder}
                  inputRef={searchInputRef}
                  InputProps={deepmerge<Partial<OutlinedInputProps>>(
                    { type: 'search' },
                    searchInputProps?.InputProps ?? {}
                  )}
                  inputProps={deepmerge<InputBaseComponentProps>(
                    {
                      sx: {
                        '&::placeholder': {
                          color: 'inherit',
                          opacity: '1',
                          ...placeholderStyles,
                        },
                        '&::-webkit-search-cancel-button': {
                          display: 'none',
                        },
                      },
                      autoFocus: true,
                      autoComplete: 'new-password',
                    },
                    searchInputProps?.inputProps ?? {}
                  )}
                  {...searchInputProps}
                  sx={deepmerge<SxProps>(
                    {
                      height: '40px',
                      width: '100%',
                      overflow: 'hidden',
                      '.MuiInputBase-input': {
                        height: 'calc(40px - 2 * 8px)',
                        width: '100%',
                        padding: '8px 12px',
                        fontSize: '14px',
                        fontWeight: '400',
                        color: 'inherit',
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '.MuiInputBase-root': {
                        color: 'inherit',
                        borderRadius: '8px',
                        '&.Mui-focused': {
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        },
                        ':hover': {
                          '.MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                          },
                        },
                      },
                    },
                    searchInputProps?.sx ?? {}
                  )}
                  value={searchTerm ?? ''}
                  onChange={({ target: { value } }) => {
                    setSearchTerm(value);
                  }}
                  onKeyDown={(event) => event.stopPropagation()}
                />
              </ListSubheader>
            ) : (
              typeof placeholder === 'string' &&
              placeholder.length > 0 && (
                <MenuItem value="" disabled={true}>
                  {placeholder}
                </MenuItem>
              )
            )}
            {filteredOptions.map(({ name, value }, index) => {
              return (
                <MenuItem
                  key={index}
                  value={value}
                  onClick={() => onMenuItemClick?.(value as T)}
                >
                  {typeof renderOption === 'function'
                    ? renderOption({ name, value })
                    : name}
                </MenuItem>
              );
            })}
            {filteredOptions.length === 0 && (
              <ListSubheader
                css={css`
                  width: 100%;
                `}
              >
                <EmptyDataMessage
                  message={optionsEmptyMessage || 'No options to display'}
                  containerStyles={css`
                    height: auto;
                  `}
                />
              </ListSubheader>
            )}
          </Select>
        </Tooltip>
        {children ? children : null}
      </StyledFormControl>
    </Box>
  );
};

export default DropdownComponent;
